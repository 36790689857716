
 
body{
  /* background-image: url("https://tamilwordle.net/game_wordle/confetti.jpg");
  background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover; */
} 
.App {
  text-align: center;
  background-color: var(--bg-black-color);
  color: var(--app-color);
  /* max-content */
  /* height: 100vh; */
  max-height: -webkit-fill-available;
  
  display: flex;
  flex-direction: column;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.App .game-header{
  height: 65px;
}

.App .offcanvas{
  background-color: var(--bg-black-color);
  color: var(--app-color);
}
/* .App .offcanvas-body a{
  color: var(--app-color);
} */
.App .nav-link{
  color: var(--navbar-color);
}
.App .nav-link.active{
  color: var(--navbar-active-color);
}

.App .offcanvas-body li:hover{
  background-color: var(--hover-color);
}

.App .navbar{
  background-color: #6B2D5C;;
  border-bottom: 1px solid var(--cube-border);
  padding: 0;
  height: 65px;
}
.App .navbar-toggler{
  color: #FFFFFF;
}

.delfont {
  padding: 10px !important;
}

.App .navbar-brand{
  font-size: 33px;
  font-weight: 900;
  letter-spacing: 0.01em;
  padding: 0;
  color: var(--app-color);
}
@media (max-width: 992px) {
  .App .navbar-brand{
    font-size: 28px;
  }
}

.dismiss-button{
  border: 0;
  font-size: 1.2em;
  background-color: var(--modal-content-bg);
  color: var(--app-color);
  border-color: var(--modal-content-bg);
}

.side-buttons .side-buttons-icons{
 letter-spacing: 2em;
 cursor: pointer;
 color: var(--app-color);
 background-color: var(--bg-black-color);
 /* border-color: var(--bg-black-color); */
 border:0;
}
.navbar-brand{
  margin-left: 8%;
}

.next-head{
  /* position: relative;
  top: 76px; */
  padding: 6px;
}

.div2{
  float:right;
  display: inline;
  
}

.timerdiv {
  background: #FFE3F8;
  border-radius: 8px;
  padding: 3px;
}

.div1{
  float:right;
  display: inline;
}

.home {
  float: left;
}

.game-body{
  width: 100%;
  max-width: 500px;
  margin:0 auto;
  position: relative;
  /* top: 50px; */
  /* margin-top: 10%; */
  /* height: calc(100% - 65px); */
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.Board-module{
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  /* height: calc(100% - 200px); */

}
.Board-module-board{
    width: 350px;
    height: 420px;
    display: grid;
    grid-template-rows: repeat(7, 1fr);
    grid-gap: 5px;
    padding: 10px;
    box-sizing: border-box;
}
.board-row{
  /* gap:5px;
  margin-top: 5px; */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;

}

#congratsimg img {
  width: 35% !important;
}
.cube{
  /* background-color: var(--bg-black-color);
  width: 100%;
  display: inline-flex;
  width: 2em;
  height: 2em;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  border: 2px solid var(--cube-border);
  vertical-align: middle;
  width: 100%; */
  background-color: #FFE3F8;
  /* border: 2px solid var(--cube-border); */
  border: 1px solid #6B2D5C;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 11%);
  width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    color: var(--app-color);
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



.Keyboard-module{
  height: 200px;
  /* margin: 0 8px; */

  background-color: var(--bg-black-color);
  /* border: 1px solid red; */
}

.Keyboard-module .keyboard-button:focus {
  border: solid 1px #00c;
}

.Keyboard-module .keyboard-button{
  /* margin: 0 6px 0 0; */
  /* width: 8vw; */
  /* height: 58px;
  border-radius: 10%;
  
  display: inline-block;
  margin-left: 0.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  color: var(--app-color);
  background-color: var(--key-bg);
  text-transform: uppercase; */

  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 3px 0 3px;
  height: 44px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e7e8ee;
  color: #084298;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}
.first-row, .second-row, .third-row{
    /* display: flex;
    justify-content: center;   */
    display: flex;
    width: 100%;
    margin: 0 auto 8px;
    touch-action: manipulation;
}

.flex-div{
  flex: 0.5;
}

.modal-content{
  background-color: #f4e2e2;
  /* background-color: var(--bg-black-color); */
}

.modal-header{
  border-bottom: none;
}

.modal-body{
  display: flex;
  flex-direction: column;
}
.settings-options{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-tone-4);
  padding: 16px 0;
}
.settings-options-texts{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.settings-options-texts small{
  /* font-size: 12px; */
  color: var(--color-tone-2);
}
.settings-options-side{
  color: var(--color-tone-2);
}
small{
  font-size: 0.7em;
  color: var(--color-tone-2);
}

.how-options{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  border-bottom: 1px solid var(--color-tone-4);
  font-family: serif;
}
.how-options h3{
  font-weight: 800;
  font-family: sans-serif;
}
.how-options h5{
  font-weight: unset;
}
.how-footer{
  text-align: start;
  margin-top: 1em;
}
.how-footer a{
  text-decoration: none;
}
.examples{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
}

.exm{
    line-height: 1;
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 1.4rem;
    text-align: center;
    line-height: normal;
}

.how-row{
  display: flex;
  grid-gap: 5px;
}
.correct{
  background-color: var(--color-correct);
}
.present{
  background-color: var(--color-present);
}
.absent{
  background-color: var(--color-absent);
}

/* #settings-modal .modal-footer{
  justify-content: center;
} */

.statistics-options{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  border-bottom: 1px solid var(--color-tone-4);
  font-family: serif;
  align-items: center;
}

.stats-boxes{
  display: flex;
  grid-gap:5px;
}
.stats-box{
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  margin-left: 12px;
}
.stats-num{
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 14px;
  margin-top: -3px;
}

#modcen {
  align-items: center;
  display: flex;    
  height: 100vh; width: 90%;
}

.stats-footer{
  display: flex;
  
  width: 100%;
  justify-content: space-between;
}
.button{
  border-radius: 105px;
  width: calc(28% - 5px);
  line-height: 25px;
  border-width: 0;
  font-size: 14px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.stats-footer .button{
  border-radius: 105px;
  width: calc(28% - 5px);
  line-height: 25px;
  border-width: 0;
  font-size: 14px;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.stats-footer .share:focus{
  background-color: var(--color-correct);
}

.spellingBeeYellow{
  background-color: var(--spellingBeeYellow);
  color: black;
}
.spellingBeeYellow:focus{
  background-color: var(--spellingBeeYellow);
}
#statistics-modal .modal-title{
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #7c1616;
  font-size: 19px;
}
#statistics-modal .stats-title{
  letter-spacing: 0.05em;
  font-weight: bold;
}
.wrd {
  border: 5px solid #5D2E46;
  padding: 2%;
  background: rgba(240, 56, 107, 0.22);
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin-left: 25%;
  margin-right: 25%; */
  font-size: 15px;
  text-align: center;
}
.correctword {
  color: #008000;
}
.statsData-box{
  display: flex;
  padding-top: 2px;
  
}
.stats-title{
  text-align: center;
}
.guess-val{
  margin-left: 5px;
  padding: 0 10px 0 10px;
  background-color: var(--color-tone-4);
}
.guess-value{
  width: 100%;
  text-align: right;
  background-color: var(--color-correct);
}
.guessdiv{
  width: 100%;
  padding: 10px;
  
}

.delfont {
  background: red !important;
  color: white !important;
  box-shadow: 0.1em 0.1em 0.2em #a3a7bd94, -0.1em -0.1em 0.2em #fff;
}
.enfont {
  background: green !important;
  color: white !important;
  padding: 10px !important; 
}
.hint-box {
  margin-top: 5%;
}

.line11 {
  background-color: #fff;
  border: none !important;
  box-shadow: none !important;
  font-size: 20px !important;
  color: #084298 !important;
}

@media only screen and (min-width: 320px) {
    .side-buttons {
      display:none !important;
    }
}

@media only screen and (min-width: 480px) {
  .side-buttons {
    display:none !important;
  }
}